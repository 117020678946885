import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["io", "ch"],
  font: "18px lato",
  iconCategories,
  iconGeneratorActive: true,
  id: "geops",
  name: "geOps",
  previewBaselayers: true,
  settings: { limit: 2 },
  slug: "geopstest",
  ssoTags: ["tp.test.geops"],
  ...colors,
  pictures: [
    { id: "single_train2_v1.png" },
    { id: "single_train_v1.png" },
    { id: "single_train_long_nose_v1.png" },
    { id: "train_long_nose_double_v1.png" },
    { id: "double_train_v1.png" },
    { id: "single_bus_v1.png" },
    { id: "double_bus_v1.png" },
    { id: "tram_v1.png" },
    { id: "tram_long_v1.png" },
    { id: "rbs_train_v1.png" },
    { id: "rbs_bus_v1.png" },
    { id: "rbs_bus2_v1.png" },
    { id: "rbs_double_bus_v1.png" },
    { id: "rbs_single_train_v1.png" },
    { id: "vbz_bus_v1.png" },
    { id: "vbz_double_bus_v1.png" },
    { id: "vbz_tram_v1.png" },
    { id: "vbz_tram_long_v1.png" },
    { id: "postbus_v1.png" },
    { id: "postbus_double_v1.png" },
    { id: "taxi_v1.png" },
  ],
};
export default config;
