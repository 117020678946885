import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["ch"],
  font: "16px arial",
  iconCategories,
  iconGeneratorActive: true,
  id: "ch",
  isDefault: true,
  name: "CH",
  settings: {
    stops_api_bbox: "5.657959,45.675482,10.722656,47.908978",
    stops_api_prefagencies: "sbb",
  },
  slug: "mapsetch",
  ssoTags: ["default"],
  ...colors,
};
export default config;
