import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["ch"],
  font: '16px "FrutigerLTStd-Light"',
  iconCategories,
  iconGeneratorActive: true,
  id: "asm", // needs to be identical to the icons directory name in public
  name: "Aare Seeland Mobil",
  slug: "asm",
  ssoTags: ["tp.ch.asm"],
  ...colors,
};
export default config;
