import { useEffect, useRef, useState } from "react";

const cache = new Map();

const defaultOptions = {};

function useFetch(
  url,
  options = defaultOptions,
  { cacheTime = 0, useCache = false } = {},
  dependencies = [],
) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const cacheTimeout = useRef(null);

  useEffect(() => {
    if (!url) return;

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      // Check cache only if useCache is true
      if (useCache && cache.has(url)) {
        setData(cache.get(url));
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(url, options);
        if (!response.ok) throw new Error("Network response was not ok");

        const result = await response.json();
        setData(result);

        if (useCache && cacheTime > 0) {
          cache.set(url, result);

          // Optional cache expiration
          if (cacheTimeout.current) clearTimeout(cacheTimeout.current);
          cacheTimeout.current = setTimeout(() => {
            cache.delete(url);
          }, cacheTime);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line consistent-return
    return () => {
      if (cacheTimeout.current) clearTimeout(cacheTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, options, useCache, cacheTime, ...dependencies]);

  return { data, error, loading };
}

export default useFetch;
