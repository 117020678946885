// Set REACT_APP_ENV in package.json/.env/.gitlab.yml files to one of the following values:

const configs = {
  // DEV
  dev_bernmobil: {
    domain: "ch",
    env: "dev",
    origin: "https://karten.dev.bernmobil.ch",
  },
  dev_ch: {
    domain: "ch",
    env: "dev",
    origin: "https://editor.dev.mapset.ch",
  },
  dev_io: {
    domain: "io",
    env: "dev",
    origin: "https://editor.dev.mapset.io",
  },
  // PROD
  prod_bernmobil: {
    domain: "ch",
    env: "prod",
    origin: "https://karten.bernmobil.ch",
  },
  prod_ch: {
    domain: "ch",
    env: "prod",
    origin: "https://editor.mapset.ch",
  },
  prod_io: {
    domain: "io",
    env: "prod",
    origin: "https://editor.mapset.io",
  },
  // STAG
  stag_bernmobil: {
    domain: "ch",
    env: "stag",
    origin: "https://karten.test.bernmobil.ch",
  },
  stag_ch: {
    domain: "ch",
    env: "stag",
    origin: "https://editor.stag.mapset.ch",
  },
  stag_io: {
    domain: "io",
    env: "stag",
    origin: "https://editor.stag.mapset.io",
  },
};

export default configs;
