// Always make sure red color is the first in the array (is selected for defaullt color for lines and polygons)
const defaultColors = [
  { border: "white", fill: [196, 41, 55, 1], hex: "#c42937", name: "red" },
  { border: "white", fill: [0, 0, 0, 1], hex: "#000000", name: "black" },
  { border: "black", fill: [0, 162, 49, 1], hex: "#00a231", name: "green" },
  { border: "white", fill: [45, 50, 125, 1], hex: "#2d327d", name: "blue" },
  { border: "black", fill: [232, 78, 15, 1], hex: "#e84e0f", name: "orange" },
  { border: "black", fill: [255, 255, 255, 1], hex: "#ffffff", name: "white" },
];

export default {
  colors: defaultColors,
  iconGeneratorColors: defaultColors,
  lineColors: defaultColors,
  polygonFillColors: defaultColors,
  polygonLineColors: defaultColors,
  textBgColors: [
    { fill: [255, 255, 255, 0.01], name: "none" },
    ...defaultColors,
  ],
};
