/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const useStyles = makeStyles((theme) => {
  return {
    box: {
      "&:hover": {
        backgroundColor: theme.colors.grayAltLight,
      },
      border: `1px solid ${theme.colors.grayAltLight}`,
      borderRadius: 2,
      height: 18,
      margin: 5,
      position: "relative",
      width: 18,
      zIndex: 2,
    },
    tick: {
      "&::after": {
        backgroundColor: theme.palette.primary.main,
        clipPath:
          "polygon(12% 52%, 0 65%, 35% 100%, 100% 30%, 88% 18%, 35% 76%)",
        content: '""',
        height: 14,
        left: 2,
        position: "absolute",
        top: 2,
        width: 14,
      },
    },
  };
});

const CheckboxIcon = React.forwardRef(
  ({ checked = false, className, ...props }, ref) => {
    const classes = useStyles();

    const classNames = useMemo(() => {
      const classNameArr = [classes.box];

      if (checked) {
        classNameArr.push(classes.tick);
      }

      if (className) {
        classNameArr.push(className);
      }
      return classNameArr.join(" ");
    }, [checked, className, classes.box, classes.tick]);

    return <div className={classNames} ref={ref} {...props} />;
  },
);

CheckboxIcon.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
};

export default CheckboxIcon;
