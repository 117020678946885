import { MaplibreLayer } from "mobility-toolbox-js/ol";

/**
 * Creates an array of MapboxLayer objects from a config object and some global values.
 * baseLayersConfig = {
 *   'basemap': {
 *     visible: true,
 *     style: 'base_bright_v2',
 *     tilesUrl: process.env.REACT_APP_VECTOR_TILES_KEY,
 *     thumbnail: path/to/thumbnail.png,
 *   }
 * }
 */
function createBaseLayers(baseLayersConfig, { tilesKey, tilesUrl } = {}) {
  return baseLayersConfig.map(
    ({ key, name, style, thumbnail, url, visible }) => {
      return new MaplibreLayer({
        apiKey: tilesKey,
        key,
        mapOptions: {
          maxCanvasSize: [Infinity, Infinity], // very important otherwise canvas limited to 4096x4096
        },
        name,
        properties: {
          isBaseLayer: true,
          thumbnail,
        },
        url: url || `${tilesUrl}/styles/${style}/style.json`,
        visible: !!visible,
        zIndex: -1,
      });
    },
  );
}

export default createBaseLayers;
